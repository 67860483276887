import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

import _ from "lodash";

import { Button, Container, Divider, Segment } from "semantic-ui-react";

// import NewPathway from './NewPathway'
import { analyticsById } from "../../graphql/queries";
import * as subscriptions from "../../graphql/subscriptions";

import {
  fetchAnalytics,
  fetchWellnessForms,
  fetchWellnessQuestions,
  removeAnalytics,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";

import colours from "../../data/colours.json";

const Index = () => {
  const dispatch = useDispatch();

  const analytics = useSelector((state) => state.analytics);
  const user = useSelector((state) => state.user);
  const wellnessForms = useSelector((state) => state.wellnessForm);
  const wellnessQuestions = useSelector((state) => state.wellnessQuestion);

  const [wellness_questions_analytics, update_wellness_questions_analytics] =
    useState([]);
  const [wellness_subscription, update_wellness_subscription] = useState([]);
  let subscriptionOnCreate;

  useEffect(() => {
    dispatch(fetchWellnessForms());
    dispatch(fetchWellnessQuestions());
  }, []);

  useEffect(() => {
    subscriptionOnCreate = API.graphql(
      graphqlOperation(subscriptions.onCreateAnalytics)
    ).subscribe({
      next: ({ provider, value }) => updateEntry(value.data.onCreateAnalytics),
      error: (error) => console.warn(error),
    });
    return () => {
      subscriptionOnCreate.unsubscribe();
    };
  }, [wellnessForms]);

  async function updateEntry(entry) {
    update_wellness_questions_analytics((wellness_questions_analytics) => [
      ...wellness_questions_analytics,
      entry,
    ]);
  }

  useEffect(() => {
    dispatch(fetchAnalytics());
    async function get_analytics() {
      try {
        const result = await API.graphql(
          graphqlOperation(analyticsById, {
            analytics_id: "1596027191_wellness_question",
          })
        );
        update_wellness_questions_analytics(result.data.AnalyticsById.items);
      } catch (error) {}
    }
    get_analytics();
  }, []);

  function renderQuestions(form) {
    const returned_questions = _.filter(wellnessQuestions, {
      form_id: form.id,
    });
    return returned_questions.map((question) => {
      const find_questions = _.filter(wellness_questions_analytics, {
        meta_1: question.form_id,
      });
      var score = 0;
      find_questions.map((qs) => {
        const object = JSON.parse(qs.data);
        score = score + object.score;
      });
      return (
        <Segment placeholder>
          <h4>{question.title}</h4>
          <div style={{ fontSize: 16 }}>
            Average Score: {(score / find_questions.length).toFixed(2)}
          </div>
          <div style={{ fontSize: 16 }}>
            Total Entries: {find_questions.length}
          </div>
        </Segment>
      );
    });
  }

  function renderForms() {
    const forms = wellnessForms.map((form) => {
      return (
        <div style={{ width: 300, margin: 10 }}>
          <h3>{form.title}</h3>
          <Divider />
          {renderQuestions(form)}
        </div>
      );
    });
    return <div style={{ display: "flex" }}>{forms}</div>;
  }

  function renderEntries() {
    return analytics.map((entry) => {
      return (
        <div>
          <div>{entry.id}</div>
          <Button onClick={() => dispatch(removeAnalytics(entry))}>
            delete
          </Button>
        </div>
      );
    });
  }

  return (
    <div>
      <h1> Analytics</h1>
      <Divider />
      <h2> Wellness Analytics</h2>
      <div>Total entries</div>
      {/* <div>{wellness_subscription.length}</div> */}
      <div >
        <div style={{ fontSize: 30, padding: 20, backgroundColor: "#0F9E97", display: 'inline-block', color: 'white', borderRadius: 10 }}>
          {wellness_questions_analytics.length}
        </div>
      </div>
      {renderForms()}

      {/* {renderEntries()} */}
      {/* <Button onClick={() => console.log(analytics)}>Analytics</Button>
           <Button onClick={() => console.log(wellnessForms)}>Wellness Forms</Button>
           <Button onClick={() => console.log(wellnessQuestions)}>   
               Check
           </Button> */}
    </div>
  );
};

export default Index;
