import React, { useState, useEffect } from 'react';

import Analytics from '../../components/analytics'
import Layout from "../../components/layout"


const Index = () => {


    return (
        <Layout>
            <Analytics/>
        </Layout>
    )
}

export default Index
